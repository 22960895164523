import { Routes } from '@angular/router';
import { authGuard } from './auth/guards/auth.guard';

export const routes: Routes = [
    {
        path: 'publico',
        loadChildren: () =>
            import('./publico/publico.route')
    },
    {
        path: 'administrador',
        loadChildren: () =>
            import('./administrador/administrador.route'),
        canActivate: [authGuard],
        data: { roles: 'ROLE_administrador' }
    },
    {
        path: 'registrador',
        loadChildren: () =>
            import('./registrador/registrador.route'),
        canActivate: [authGuard],
        data: { roles: 'ROLE_registrador' }
    },
    {
        path: 'verificador',
        loadChildren: () =>
            import('./verificador/verificador.route'),
        canActivate: [authGuard],
        data: { roles: 'ROLE_verificador' }
    },
    {
        path: 'login-patoempleos-vra',
        loadComponent: () =>
            import('./auth/login/login.component').then((m) => m.LoginComponent),
    },

    { path: '', redirectTo: 'publico', pathMatch: 'full' }, // Redirección por defecto a 'empleos'
    // { path: '**', redirectTo: '/empleos' } // Redirección para rutas no encontradas
];
