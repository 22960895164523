import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

export const authGuard: CanActivateFn = async (route, state) => {
  const jwtHelper = inject(JwtHelperService)
  var localData: any = ""
  var rol: any = ""
  if (typeof window !== 'undefined') {
    localData = localStorage.getItem('token');
    rol = jwtHelper.decodeToken(localData).rol;
  }

  const allowedRoles: string[] = route.data?.['roles'] as string[];
  return rol === allowedRoles;
};